var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ck-text-input", {
        attrs: {
          value: _vm.name,
          id: "name",
          label: "Name",
          type: "text",
          error: _vm.errors.get("name")
        },
        on: {
          input: function($event) {
            return _vm.onNameInput($event)
          }
        }
      }),
      _vm.auth.isSuperAdmin
        ? _c(
            "ck-text-input",
            {
              attrs: {
                value: _vm.slug,
                id: "slug",
                label: "Unique slug",
                type: "text",
                error: _vm.errors.get("slug")
              },
              on: {
                input: function($event) {
                  return _vm.onInput("slug", $event)
                }
              }
            },
            [
              _c(
                "gov-hint",
                { attrs: { slot: "hint", for: "slug" }, slot: "hint" },
                [
                  _vm._v("\n      This will be used to access the collection."),
                  _c("br"),
                  _vm._v(
                    "\n      e.g. /collections/" + _vm._s(_vm.slug) + "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("ck-textarea-input", {
        attrs: {
          value: _vm.intro,
          id: "intro",
          label: "Description of event collection",
          hint:
            "A short summary detailing what type of events the event collection contains.",
          maxlength: 300,
          error: _vm.errors.get("intro")
        },
        on: {
          input: function($event) {
            _vm.$emit("update:intro", $event)
            _vm.$emit("clear", "intro")
          }
        }
      }),
      _c(
        "ck-image-input",
        {
          attrs: {
            id: "image",
            label: "Event collection image",
            "file-id": _vm.image_file_id
          },
          on: {
            input: function($event) {
              return _vm.onInput("image_file_id", $event.file_id)
            },
            "image-changed": function($event) {
              return _vm.$emit("image-changed", $event)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "after-error-message" },
            [
              _vm.errors.get("image_file_id")
                ? _c("gov-error-message", {
                    attrs: { for: "image" },
                    domProps: {
                      textContent: _vm._s(_vm.errors.get("image_file_id"))
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c("collection-enabled-input", {
        attrs: {
          value: _vm.enabled,
          id: "status",
          type: "category",
          label: "Status of Event Collection",
          error: _vm.errors.get("enabled")
        },
        on: {
          input: function($event) {
            return _vm.onInput("enabled", $event)
          }
        }
      }),
      _c("gov-heading", { attrs: { size: "m" } }, [_vm._v("Sideboxes")]),
      _c("gov-body", [
        _vm._v(
          "\n    Create up to three sideboxes that will can be used to display information\n    on the event collection results page.\n  "
        )
      ]),
      _c("ck-sideboxes-input", {
        attrs: { sideboxes: _vm.sideboxes, errors: _vm.errors },
        on: {
          input: function($event) {
            return _vm.onInput("sideboxes", $event)
          }
        }
      }),
      _c("gov-label", { staticClass: "govuk-!-font-weight-bold" }, [
        _vm._v("Taxonomies")
      ]),
      _c("ck-taxonomy-input", {
        attrs: {
          root: "categories",
          invalid: _vm.errors.has("category_taxonomies"),
          value: _vm.category_taxonomies,
          error: _vm.errors.get("category_taxonomies"),
          showCollections: false
        },
        on: {
          input: function($event) {
            return _vm.$emit("update:category_taxonomies", $event)
          },
          clear: function($event) {
            return _vm.$emit("clear", "category_taxonomies")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }